import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Tile,
  OrderedList,
  ListItem,
  UnorderedList,
  Button,
} from '@carbon/react';
import { getAsessmentCandidateInfo } from '../../../actions/Assessments';
import JDProcessOverlay from '../../../components/common/JDProcessOverlay';
import NotFound from '../../../components/common/NotFound';
import _ from 'lodash';

import './CandidateTestSummary.css';

const AssessmentLink = ({
  testName,
  status,
  isTestLinkExpired,
  link,
  scenarioId,
}) => {
  return (
    <div className="">
      {testName === 'uCognify' ? (
        <h4 className="">
          Please click the following link to start the{' '}
          <span className="ml-1 cds--type-bold">
            Game-based Assessment (GBA)
          </span>
          .
        </h4>
      ) : testName === 'Neo Profile' ? (
        <h4 className="">
          Please click the following link to start the
          <span className="ml-1 cds--type-bold">Personality Questionnaire</span>
          .
        </h4>
      ) : testName === 'ARS Video' ? (
        <h4 className="mt-2">
          Please click the following link to start your
          <span className="ml-1 cds--type-bold">Video Presentation</span>.
        </h4>
      ) : (
        ''
      )}
      {status?.toLowerCase() === 'completed' ? (
        <div className="">
          {testName
            ? testName === 'uCognify' || testName === 'Neo Profile'
              ? 'Assessment Completed: You have completed this assessment as part of your PSC scholarship application, PAE, or during a test session for a government scholarship.'
              : 'Assessment Completed.'
            : 'Feedback Completed.'}
        </div>
      ) : (
        <>
          {isTestLinkExpired ? (
            <div className="text-danger">Test Link has been expired!</div>
          ) : (
            <>
              {scenarioId == 4 && testName === 'uCognify' && (
                <div>
                  <span className="lh-sm" style={{ fontSize: '1rem' }}>
                    Important Note:
                  </span>
                  <span className="lh-sm ml-2" style={{ fontSize: '1rem' }}>
                    A few screens after you clicked on the link below, you will
                    be shown an “Event-ID” for the GBA. You can ignore the
                    “Event-ID” as it will be automatically recorded by the
                    system. Therefore, you will not receive any email regarding
                    your “Event-ID”. If you exit the web page or encounter
                    technical difficulties during the GBA, you can come back to
                    this dashboard to access the assessment.
                  </span>
                </div>
              )}
              <div className="d-flex">
                <h5>Invitation Link:</h5>
                <div className="ml-3">
                  <h5>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href={link}
                      className="xpa-link text-capitalize">
                      Click Here
                    </a>
                  </h5>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};
class CandidateTestSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.handleGetCandidateInfo();
    // this.candInfoInterval = setInterval(
    //   () => this.handleGetCandidateInfo(),
    //   20000
    // );
  }

  // componentWillUnmount() {
  //   clearInterval(this.candInfoInterval);
  // }

  handleGetCandidateInfo = () => {
    const { location: { state } = {} } = this.props;
    const { emailId, candidateId } = state || {};
    if (emailId || candidateId) {
      this.props
        .getAsessmentCandidateInfo({ emailId, candidateId })
        .then((res) => {
          if (res && !res.error) {
            const { data } = res || {};
            const assessCandInfo =
              (data && Array.isArray(data) && data?.[0]) || {};
            this.setState({ assessCandInfo });
          }
        });
    }
  };

  render() {
    const { location: { state: locationState } = {} } = this.props;
    const { isCandRegistered } = locationState || {};
    const { assessCandInfo } = this.state;
    let {
      firstName,
      lastName,
      fullName,
      assessmentInfo = [],
      feedbackInfo = [],
      Scenario,
    } = assessCandInfo || {};

    const { scenarioId } = Scenario || {};

    let finalFeedbackInfoList = feedbackInfo;
    // PSC scenario
    if (scenarioId == 4)
      finalFeedbackInfoList =
        feedbackInfo?.filter(
          (itm) => itm?.feedbackType?.toLowerCase() !== 'gba'
        ) || [];

    const gbaAssessObj =
      assessmentInfo &&
      Array.isArray(assessmentInfo) &&
      assessmentInfo.length > 0 &&
      assessmentInfo?.find(
        (itm) => itm?.Scenariotestmap?.Test?.testName === 'uCognify'
      );
    const gbaAssessStatus = gbaAssessObj?.assessmentStatus || '';

    const neoProfileAssessObj =
      assessmentInfo &&
      Array.isArray(assessmentInfo) &&
      assessmentInfo.length > 0 &&
      assessmentInfo?.find(
        (itm) => itm?.Scenariotestmap?.Test?.testName === 'Neo Profile'
      );
    const neoProfileAssessStatus = neoProfileAssessObj?.assessmentStatus || '';

    const areAllAssessmentsCompleted =
      (assessmentInfo &&
        Array.isArray(assessmentInfo) &&
        assessmentInfo.length > 0 &&
        assessmentInfo?.every(
          (itm) => itm?.assessmentStatus?.toLowerCase() === 'completed'
        )) ||
      false;

    // PAE scenario
    if (scenarioId == 5) {
      assessmentInfo =
        assessmentInfo &&
        Array.isArray(assessmentInfo) &&
        assessmentInfo.length > 0 &&
        assessmentInfo.filter(
          (itm) => itm?.Scenariotestmap?.Test?.testName !== 'Neo Profile'
        );
    }

    return (
      <div>
        {!_.isEmpty(assessCandInfo) ? (
          <>
            <div className="cds--row justify-content-end mr-3">
              <div className="my-2">
                <Button
                  size="sm"
                  kind="tertiary"
                  onClick={() => this.handleGetCandidateInfo()}>
                  Refresh page
                </Button>
              </div>
            </div>
            <Tile>
              <h2 className="mt-2 mb-4">
                Dear <span className="text-capitalize">{fullName}</span>,
              </h2>
              <div className="">
                {scenarioId == 4 && (
                  <div className="">
                    <h4 className="">
                      You are advised to take the assessments in a conducive
                      environment and set aside sufficient time in order to
                      minimise disruptions and allow you to perform optimally.
                      You may complete each assessment on different days, but
                      each assessment should be completed in one sitting.
                    </h4>
                    <h4 className="mt-2 mb-3">
                      By taking the assessments, you confirm that you are:
                      <div className="ml-4 psc-appl-bullet-points">
                        <UnorderedList style={{ listStyle: 'disc' }}>
                          <ListItem>
                            <h4>
                              doing so honestly and without help or advice of
                              others
                            </h4>
                          </ListItem>
                          <ListItem>
                            <h4>
                              treating the assessments as confidential and that
                              you will not reveal the content to others
                            </h4>
                          </ListItem>
                          <ListItem>
                            <h4>
                              respecting the legal rights pertaining to the
                              content of the assessments (e.g., copyright and
                              trademarks)
                            </h4>
                          </ListItem>
                        </UnorderedList>
                      </div>
                    </h4>
                  </div>
                )}

                {assessmentInfo &&
                  Array.isArray(assessmentInfo) &&
                  assessmentInfo.length > 0 &&
                  assessmentInfo.map((res) => {
                    const {
                      Scenariotestmap,
                      assessmentLink,
                      assessmentStatus,
                      expiryDate,
                      markAsExpired,
                    } = res || {};
                    const { Test } = Scenariotestmap || {};
                    const { testName } = Test || {};
                    const isTestLinkExpired =
                      expiryDate &&
                      (new Date(expiryDate).getTime() < new Date().getTime() ||
                        markAsExpired);
                    return (
                      <div className="mb-4">
                        <div className="cds--row  m-0">
                          {scenarioId == 4 ? (
                            <>
                              <AssessmentLink
                                testName={testName}
                                status={assessmentStatus}
                                isTestLinkExpired={isTestLinkExpired}
                                link={assessmentLink}
                                scenarioId={scenarioId}
                              />
                            </>
                          ) : (
                            <>
                              {(testName === 'uCognify' ||
                                testName === 'Neo Profile') && (
                                <AssessmentLink
                                  testName={testName}
                                  status={assessmentStatus}
                                  isTestLinkExpired={isTestLinkExpired}
                                  link={assessmentLink}
                                  scenarioId={scenarioId}
                                />
                              )}

                              {testName === 'ARS Video' && (
                                <div className="">
                                  <h4 className="">
                                    As part of your application, we would
                                    require you to complete a video presentation
                                    on X0PA, an external vendor engaged to
                                    support this component.
                                  </h4>
                                  <h4 className="mt-2">
                                    The video presentation questions are
                                    designed to help PSC know more about you and
                                    your career aspirations. There are no right
                                    or wrong answers and we encourage you to be
                                    yourself.
                                  </h4>
                                  <div className="mt-2">
                                    <h5>
                                      <u>Administrative instructions:</u>
                                    </h5>
                                    <h4 className="mt-2">
                                      The video presentation can be done at your
                                      own time and on your own device, and may
                                      take up to{' '}
                                      <span className="cds--type-bold">
                                        60 minutes
                                      </span>{' '}
                                      in total. You will{' '}
                                      <span className="cds--type-bold">
                                        not be allowed
                                      </span>{' '}
                                      to leave the assessment halfway. Thus,
                                      please ensure that you are able to
                                      complete your video interview in{' '}
                                      <span className="cds--type-bold">
                                        one sitting
                                      </span>
                                      . You are advised to attempt the video
                                      presentation when you have access to a{' '}
                                      <span className="cds--type-bold">
                                        stable internet connection
                                      </span>
                                      , and are in a suitable environment with
                                      minimal distractions where you can speak
                                      freely. Please check your lighting
                                      conditions and surroundings before
                                      starting the video presentation. Please
                                      also ensure that your device has
                                      sufficient battery if you are using a
                                      mobile phone or laptop for the
                                      presentation.
                                    </h4>
                                    <div className="mt-2">
                                      <div className="cds--type-bold">
                                        Here are some points to note about the
                                        video presentation:
                                      </div>
                                      <div className="ml-4">
                                        <OrderedList>
                                          <ListItem>
                                            Please perform the audio and video
                                            test when the system prompts you to
                                            and ensure you can be seen and heard
                                            clearly in your recording.
                                          </ListItem>
                                          <ListItem>
                                            There is a practice question
                                            included for you to familiarise
                                            yourself with the system and to
                                            ensure that the system is able to
                                            record your responses. Please make
                                            sure that your recorded response to
                                            the practice question captures your
                                            face on the screen and your voice
                                            when you speak.
                                          </ListItem>
                                          <ListItem>
                                            Your responses to the practice
                                            question will not be uploaded or
                                            seen by us.
                                          </ListItem>
                                          <ListItem>
                                            The video presentation consists a
                                            total of 3 mandatory questions.
                                          </ListItem>
                                          <ListItem>
                                            The questions will appear
                                            sequentially (i.e. question 1,
                                            followed by question 2 and question
                                            3).
                                          </ListItem>
                                          <ListItem>
                                            You will have 60 seconds to read
                                            each question and plan your
                                            response.
                                          </ListItem>
                                          <ListItem>
                                            You are given a maximum of 90
                                            seconds to record your response to
                                            each question.
                                          </ListItem>
                                          <ListItem>
                                            You do not need to use the full 90
                                            seconds and may stop when you have
                                            completed your response.
                                          </ListItem>
                                          <ListItem>
                                            You have 3 attempts per question,
                                            but you may submit your response
                                            after the first attempt.
                                          </ListItem>
                                          <ListItem>
                                            If you re-attempt the question, your{' '}
                                            <span className="cds--type-bold">
                                              previous
                                            </span>
                                            response will be{' '}
                                            <span className="cds--type-bold">
                                              deleted
                                            </span>{' '}
                                            and the latest response will be
                                            submitted.
                                          </ListItem>
                                          <ListItem>
                                            If you wish to re-attempt the
                                            question, please do so within 120
                                            seconds of your last attempt.
                                          </ListItem>
                                        </OrderedList>
                                      </div>
                                      <div className="my-2">
                                        <table className="table-border w-75 table-zoom-auto">
                                          <thead className="text-center heading-align">
                                            <th className=" css-s-no-w"> </th>
                                            <th className="">
                                              <div className="">
                                                No. Of Attempts
                                              </div>
                                            </th>
                                            <th className=" ">
                                              <div>
                                                Video will <u>start</u>{' '}
                                                recording after:
                                              </div>
                                            </th>
                                            <th className=" ">
                                              <div>
                                                Video will <u>stop</u> recording
                                                after:
                                              </div>
                                            </th>
                                            <th className=" ">
                                              <div>
                                                Current attempt will <u>end</u>{' '}
                                                and next attempt (if applicable)
                                                will <u>start</u> after:
                                              </div>
                                            </th>
                                          </thead>
                                          <tbody className="table-border">
                                            <tr className="text-left">
                                              <td>Audio/Video Test</td>
                                              <td>(As needed)</td>
                                              <td>60 Sec</td>
                                              <td>90 Sec</td>
                                              <td>120 Sec</td>
                                            </tr>
                                            <tr className="text-left">
                                              <td>Practice Session</td>
                                              <td>3</td>
                                              <td>60 Sec</td>
                                              <td>90 Sec</td>
                                              <td>120 Sec</td>
                                            </tr>
                                            <tr className="text-left">
                                              <td>Question 1</td>
                                              <td>3</td>
                                              <td>60 Sec</td>
                                              <td>90 Sec</td>
                                              <td>120 Sec</td>
                                            </tr>
                                            <tr className="text-left">
                                              <td>Question 2</td>
                                              <td>3</td>
                                              <td>60 Sec</td>
                                              <td>90 Sec</td>
                                              <td>120 Sec</td>
                                            </tr>
                                            <tr className="text-left">
                                              <td>Question 3</td>
                                              <td>3</td>
                                              <td>60 Sec</td>
                                              <td>90 Sec</td>
                                              <td>120 Sec</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mt-4">
                                    <h5>
                                      <u>Technical instructions:</u>
                                    </h5>
                                    <div className="mt-3">
                                      <div className="cds--type-bold">
                                        If you are using a desktop / laptop:
                                      </div>

                                      <div className="ml-4">
                                        <UnorderedList>
                                          <ListItem>
                                            Please use either Google Chrome,
                                            Firefox or Microsoft Edge as your
                                            browser. Safari, Internet Explorer
                                            is currently not supported.
                                          </ListItem>
                                          <ListItem>
                                            Make sure that you have a webcam and
                                            microphone set up before starting
                                            your video presentation.
                                          </ListItem>
                                        </UnorderedList>
                                      </div>
                                    </div>
                                    <div className="mt-3">
                                      If you are using a mobile device (mobile
                                      phone or tablet):
                                      <ul>
                                        <ListItem>
                                          You will only be able to use an
                                          Android device. Devices that operate
                                          on iOS (iPhone and iPad) are not
                                          supported.
                                        </ListItem>
                                        <ListItem>
                                          Please set your device to do not
                                          disturb mode before starting the
                                          assessment.
                                        </ListItem>
                                        <ListItem>
                                          If your phone does not fulfil the
                                          above requirements, please attempt the
                                          video presentation on a desktop or
                                          laptop.
                                        </ListItem>
                                      </ul>
                                    </div>
                                  </div>
                                  <AssessmentLink
                                    testName={testName}
                                    status={assessmentStatus}
                                    isTestLinkExpired={isTestLinkExpired}
                                    link={assessmentLink}
                                    scenarioId={scenarioId}
                                  />
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    );
                  })}

                {scenarioId == 4 && (
                  <div className="my-3">
                    <h4 className="">
                      Upon completing all 3 assessments, please give us some
                      <span className="ml-1 cds--type-bold">
                        feedback on your PSC Scholarship application experience
                      </span>
                      .
                    </h4>
                  </div>
                )}

                {finalFeedbackInfoList &&
                  Array.isArray(finalFeedbackInfoList) &&
                  finalFeedbackInfoList.length > 0 &&
                  finalFeedbackInfoList?.map((res, idx) => {
                    const { feedbackType, url, status, scorecardTemplateName } =
                      res || {};

                    return (
                      (feedbackType === 'custom-feedback' ||
                        (feedbackType === 'gba' &&
                          gbaAssessStatus?.toLowerCase() === 'completed') ||
                        (scenarioId == 4
                          ? areAllAssessmentsCompleted
                          : feedbackType === 'psc-application-process' &&
                            neoProfileAssessStatus?.toLowerCase() ===
                              'completed')) && (
                        <div className="mb-4">
                          <div className=" cds--row  m-0">
                            <div className="">
                              <h4 className="">
                                {scorecardTemplateName ?? 'Feedback Link'}
                              </h4>
                              <AssessmentLink
                                status={status}
                                link={url}
                                scenarioId={scenarioId}
                              />
                            </div>
                          </div>
                        </div>
                      )
                    );
                  })}
              </div>
            </Tile>
          </>
        ) : (
          <>{!this.props.loading && <NotFound />}</>
        )}
        <JDProcessOverlay show={this.props.loading} message="processing..." />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.Assessments.loading,
  assessCandInfo: state.Assessments.assessCandInfo,
});

const mapDispatchToProps = {
  getAsessmentCandidateInfo,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CandidateTestSummary)
);
